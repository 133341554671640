<template>
    <div class="flex h-full flex-1 flex-col">
        <splitpanes class="documents-splitpanes">
            <pane size="20" min-size="20" class="bg-white">
                <div class="relative p-2.5">
                    <accountant-clients-documents-folders
                        v-for="document in documents"
                        :key="document.uuid"
                        :document="document"
                    />
                </div>
            </pane>

            <pane min-size="65" size="80">
                <accountant-clients-documents-items
                    :tenant-id="tenantId"
                    @refresh-grid="loadDirectories"
                />
            </pane>
        </splitpanes>
    </div>
</template>
<script setup>
import { Pane, Splitpanes } from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'

const { tenantId } = useAuth()
const { execute: getDirectories, result: documents } = useApi(
    '/api/accountant-documents/directories',
    'GET'
)

onMounted(() => {
    loadDirectories()
})

const loadDirectories = () => {
    getDirectories()
}
</script>
