const navigation = [
    {
        id: 'sas-my-accountant-documents',
        path: 'my-accountant.documents',
        label: 'myAccountant.documents',
        parent: 'sas-my-accountant',
        position: 3,
    },
]

export default navigation
